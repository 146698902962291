import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import LandingPage from 'src/components/LandingPage/LandingPage'

const LpGeneralPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero-wedding.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const heroProps = {
    titleText: `Look amazing for your one special day!`,
    subtitleText: `Lose weight as fast as humanly possible without feeling hungry! Guaranteed Results!`,
    image: data.hero.childImageSharp.fluid,
  }
  return <LandingPage location={location} heroProps={heroProps} />
}

export default LpGeneralPage
